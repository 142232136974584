import { createRouter, createWebHistory } from "vue-router";

import Index from "@/views/frontend/pages/home/index.vue";

import AboutUs from "@/views/frontend/pages/pages/about-us.vue";

import ContactUs from "@/views/frontend/pages/pages/contact-us.vue";
import ForgotPassword from "@/views/frontend/pages/pages/authentication/forgot-password.vue";
import EmailOtp from "@/views/frontend/pages/pages/authentication/email-otp.vue";
import LoginPage from "@/views/frontend/pages/pages/authentication/login.vue";
import LoginWithToken from "@/views/frontend/pages/pages/authentication/loginWithToken.vue";
import register from "@/views/frontend/pages/pages/authentication/register.vue";
import patientRegister from "@/views/frontend/pages/pages/authentication/patientRegister.vue";
import doctorRegister from "@/views/frontend/pages/pages/authentication/doctorRegister.vue";
import Error404 from "@/views/frontend/pages/pages/errorpages/error-404.vue";
import Error500 from "@/views/frontend/pages/pages/errorpages/error-500.vue";
import Privacypolicy from "@/views/frontend/pages/pages/privacy-policy.vue";
import TermsCondition from "@/views/frontend/pages/pages/terms-condition.vue";
import FAQ from "@/views/frontend/pages/pages/faq.vue";
import Profile_Settings from "@/views/frontend/pages/patients/profile-settings.vue";
import BlogList from "@/views/frontend/pages/blog/blog-list.vue";
import BlogDetails from "@/views/frontend/pages/blog/blog-details.vue";
import serviceGuide from "@/views/frontend/pages/serviceGuide.vue";
import administrativeTeam from "@/views/frontend/pages/administrativeTeam.vue";
import doctors from "@/views/frontend/pages/doctors.vue";
import doctorDetails from "@/views/frontend/pages/doctor-details.vue";
import Paitentdetails from "@/views/frontend/pages/patients/patient-details.vue";
import Search from "@/views/frontend/pages/patients/searchdoctor/search.vue";
import Medical_Details from "@/views/frontend/pages/patients/medical-details.vue";
import my_appointment from "@/views/frontend/pages/patients/my-appointment/my-appointment.vue";
import Orders_List from "@/views/frontend/pages/patients/orders-list.vue";

//Doctor Pages
import Social_Media from "@/views/frontend/pages/doctors/social-media.vue";
import Reviews_Index from "@/views/frontend/pages/doctors/reviews-index.vue";
import Doctor_Profile_Settings from "@/views/frontend/pages/doctors/doctor-profile-settings/doctor-profile-settings.vue";
import Doctor_Specialities from "@/views/frontend/pages/doctors/doctor-specialities.vue";
import DoctorDashBoard from "@/views/frontend/pages/doctors/dashboard/doctor-dashboard.vue";
import Signupsuccess from "@/views/frontend/pages/pages/signup-success.vue";
import Consultation from "@/views/frontend/pages/pages/consultation.vue";
import Accounts from "@/views/frontend/pages/doctors/accounts/accounts.vue";
import OnboardingEmaill from "@/views/frontend/pages/onboard/onboarding-email.vue";
import OnboardingEmaillOtp from "@/views/frontend/pages/onboard/onboarding-email-otp.vue";
import OnboardingPhone from "@/views/frontend/pages/onboard/onboarding-phone.vue";
import OnboardingPhoneOtp from "@/views/frontend/pages/onboard/onboarding-phone-otp.vue";
import OnBoardingPassword from "@/views/frontend/pages/onboard/onboarding-password.vue";
import OnBoardingIdentity from "@/views/frontend/pages/onboard/onboarding-identity.vue";
import OnBoardingPersonalize from "@/views/frontend/pages/onboard/onboarding-personalize.vue";
import OnBoardingVerification from "@/views/frontend/pages/onboard/onboarding-verification.vue";
import OnBoardingPreferences from "@/views/frontend/pages/onboard/onboarding-preferences.vue";
import OnBoardingAvailability from "@/views/frontend/pages/onboard/onboarding-availability.vue";
import OnBoardingConsultation from "@/views/frontend/pages/onboard/onboarding-consultation.vue";
import OnBoardingCost from "@/views/frontend/pages/onboard/onboarding-cost.vue";
import OnBoardingStepVerify from "@/views/frontend/pages/onboard/onboarding-email-step-2-verify.vue";
import OnBoardingLock from "@/views/frontend/pages/onboard/onboarding-lock.vue";
import OnBoardingVerifyAccount from "@/views/frontend/pages/onboard/onboarding-verify-account.vue";
import DoctorRequest from "@/views/frontend/pages/doctors/doctor-request.vue";
import Appointments from "@/views/frontend/pages/doctors/appointments/appointments.vue";
import DoctorUpcomingAppointment from "@/views/frontend/pages/doctors/appointments/doctor-upcoming-appointment.vue";
import DoctorAppointmentStart from "@/views/frontend/pages/doctors/appointments/doctor-appointment-start.vue";
import DoctorCancelledAppointment from "@/views/frontend/pages/doctors/appointments/doctor-cancelled-appointment.vue";
import DoctorCancelledAppointmentTwo from "@/views/frontend/pages/doctors/appointments/doctor-cancelled-appointment-two.vue";
import DoctorCompletedAppointment from "@/views/frontend/pages/doctors/appointments/doctor-completed-appointment.vue";
import AvailableTimings from "@/views/frontend/pages/doctors/scheduletiming/available-timings.vue";
import RequestAppointment from "@/views/frontend/pages/patients/request-appointment.vue";
import MyPatients from "@/views/frontend/pages/doctors/patients/my-patients.vue";
import PatientProfile from "@/views/frontend/pages/doctors/patients/patient-profile.vue";
import ResetPassword from "@/views/frontend/pages/pages/authentication/reset-password.vue";

//add permission
import store from "../store/index";

const hasAccess = (role) => (to, from, next) => {
  const { userData } = store.getters;

  const hasRole = role == userData.doctorOrPtient;
  hasRole
    ? next()
    : next({
        name: "index",
      });
};

const routes = [
  /**************** Frontend  *************/
  {
    path: "/",
    name: "index",
    component: Index,
  },
  {
    path: "/service-guide",
    name: "serviceGuide",
    component: serviceGuide,
  },
  {
    path: "/doctors",
    name: "doctors",
    component: doctors,
  },
  {
    path: "/doctor-details",
    name: "doctorDetails",
    component: doctorDetails,
  },

  {
    path: "/administrative-team",
    name: "administrativeTeam",
    component: administrativeTeam,
  },
  {
    path: "/blog-list",
    name: "blog-list",
    component: BlogList,
  },
  {
    path: "/blog-details",
    name: "blog-details",
    component: BlogDetails,
  },
  {
    path: "/about-us",
    name: "about-us",
    component: AboutUs,
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: ContactUs,
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage,
  },
  {
    path: "/login-with-token",
    name: "loginWithToken",
    component: LoginWithToken,
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: ResetPassword,
  },
  {
    path: "/email-otp",
    name: "email-otp",
    component: EmailOtp,
  },
  {
    path: "/error-404",
    name: "error-404",
    component: Error404,
  },
  {
    path: "/error-500",
    name: "error-500",
    component: Error500,
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: Privacypolicy,
  },
  {
    path: "/terms-condition",
    name: "terms-condition",
    component: TermsCondition,
  },
  {
    path: "/faq",
    name: "faq",
    component: FAQ,
  },
  {
    path: "/profile-settings",
    name: "profile-settings",
    component: Profile_Settings,
    beforeEnter: hasAccess("isPatient"),
  },
  {
    path: "/patient-details",
    name: "patient-details",
    component: Paitentdetails,
  },
  {
    path: "/search",
    name: "search",
    component: Search,
    beforeEnter: hasAccess("isPatient"),
  },
  {
    path: "/doctor-specialities",
    name: "doctor-specialities",
    component: Doctor_Specialities,
  },
  {
    path: "/medical-details",
    name: "medical-details",
    component: Medical_Details,
    beforeEnter: hasAccess("isPatient"),
  },
  {
    path: "/my-appointment",
    name: "my-appointment",
    component: my_appointment,
    beforeEnter: hasAccess("isPatient"),
  },
  {
    path: "/orders-list",
    name: "orders-list",
    component: Orders_List,
  },
  {
    path: "/doctor-dashboard",
    name: "doctor-dashboard",
    component: DoctorDashBoard,
    beforeEnter: hasAccess("isDoctor"),
  },
  {
    path: "/social-media",
    name: "social-media",
    component: Social_Media,
  },
  {
    path: "/reviews",
    name: "reviews",
    component: Reviews_Index,
  },
  {
    path: "/doctor-profile-settings",
    name: "doctor-profile-settings",
    component: Doctor_Profile_Settings,
    beforeEnter: hasAccess("isDoctor"),
  },
  {
    path: "/signup-success",
    name: "signup-success",
    component: Signupsuccess,
  },
  {
    path: "/consultation",
    name: "consultation",
    component: Consultation,
  },
  {
    path: "/accounts",
    name: "accounts",
    component: Accounts,
  },
  {
    path: "/register",
    name: "register",
    component: register,
  },
  {
    path: "/patient-register",
    name: "patientRegister",
    component: patientRegister,
  },
  {
    path: "/doctor-register",
    name: "doctorRegister",
    component: doctorRegister,
  },
  {
    path: "/onboarding-email",
    name: "onboarding-email",
    component: OnboardingEmaill,
  },
  {
    path: "/onboarding-email-otp",
    name: "onboarding-email-otp",
    component: OnboardingEmaillOtp,
  },
  {
    path: "/onboarding-phone",
    name: "onboarding-phone",
    component: OnboardingPhone,
  },
  {
    path: "/onboarding-phone-otp",
    name: "onboarding-phone-otp",
    component: OnboardingPhoneOtp,
  },
  {
    path: "/onboarding-password",
    name: "onboarding-password",
    component: OnBoardingPassword,
  },
  {
    path: "/onboarding-identity",
    name: "/onboarding-identity",
    component: OnBoardingIdentity,
  },
  {
    path: "/onboarding-personalize",
    name: "onboarding-personalize",
    component: OnBoardingPersonalize,
  },
  {
    path: "/onboarding-verification",
    name: "onboarding-verification",
    component: OnBoardingVerification,
  },
  {
    path: "/onboarding-preferences",
    name: "onboarding-preferences",
    component: OnBoardingPreferences,
  },
  {
    path: "/onboarding-availability",
    name: "onboarding-availability",
    component: OnBoardingAvailability,
  },
  {
    path: "/onboarding-consultation",
    name: "onboarding-consultation",
    component: OnBoardingConsultation,
  },
  {
    path: "/onboarding-cost",
    name: "onboarding-cost",
    component: OnBoardingCost,
  },
  {
    path: "/onboarding-email-step-2-verify",
    name: "onboarding-email-step-2-verify",
    component: OnBoardingStepVerify,
  },
  {
    path: "/onboarding-lock",
    name: "onboarding-lock",
    component: OnBoardingLock,
  },
  {
    path: "/onboarding-verify-account",
    name: "onboarding-verify-account",
    component: OnBoardingVerifyAccount,
  },
  {
    path: "/doctor-request",
    name: "doctor-request",
    component: DoctorRequest,
  },
  {
    path: "/appointments",
    name: "appointments",
    component: Appointments,
    beforeEnter: hasAccess("isDoctor"),
  },
  {
    path: "/doctor-upcoming-appointment",
    name: "doctor-upcoming-appointment",
    component: DoctorUpcomingAppointment,
  },
  {
    path: "/doctor-appointment-details",
    name: "doctor-appointment-start",
    component: DoctorAppointmentStart,
    beforeEnter: hasAccess("isDoctor"),
  },
  {
    path: "/doctor-cancelled-appointment",
    name: "doctor-cancelled-appointment",
    component: DoctorCancelledAppointment,
  },
  {
    path: "/doctor-cancelled-appointment-2",
    name: "doctor-cancelled-appointment-two",
    component: DoctorCancelledAppointmentTwo,
  },
  {
    path: "/doctor-completed-appointment",
    name: "doctor-completed-appointment",
    component: DoctorCompletedAppointment,
  },
  {
    path: "/available-timings",
    name: "available-timings",
    component: AvailableTimings,
    beforeEnter: hasAccess("isDoctor"),
  },
  {
    path: "/request-appointment",
    name: "request-appointment",
    component: RequestAppointment,
  },

  {
    path: "/my-patients",
    name: "my-patients",
    component: MyPatients,
  },
  {
    path: "/patient-profile",
    name: "patient-profile",
    component: PatientProfile,
  },
];

export const router = createRouter({
  mode: history,
  history: createWebHistory(), // You can pass a base URL if needed, but empty string defaults to root
  linkActiveClass: "active",
  routes,
});

router.beforeEach((to, from, next) => {
  const isSameRoute = to.name === from.name;

  if (!isSameRoute) {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  // Continue with the navigation
  next();
});
// Optionally, you can add a global after guard to handle additional behavior
router.afterEach((to, from) => {
  // Any additional behavior can be handled here after navigation
});
