<template>
  <div class="">
    <section class="banner-section">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="banner-content aos" data-aos="fade-up">
              <h1>
                {{ $t("hospital.title") }}
                <span class="text-white">{{ $t("hospital.subtitle") }}</span>
              </h1>
              <img
                src="@/assets/img/icons/header-icon.svg"
                class="header-icon d-none"
                alt="header-icon"
              />
              <div
                class="banner-arrow-img"
                v-if="isLoggedIn && userData.doctorOrPtient == 'isPatient'"
              >
                <img
                  src="@/assets/img/down-arrow-img.png"
                  class="img-fluid"
                  alt="Image"
                />
              </div>
            </div>
            <div
              class="search-box-one aos"
              data-aos="fade-up"
              v-if="isLoggedIn && userData.doctorOrPtient == 'isPatient'"
            >
              <form @submit.prevent="submitForm">
                <div class="">
                  <div class="search-input search-line">
                    <div class="form-group mb-0 border">
                      <input
                        v-model="searchModel.name"
                        type="text"
                        class="form-control"
                        placeholder="Search doctors, clinics, hospitals, etc"
                      />
                    </div>
                  </div>
                  <div class="search-input search-map-line">
                    <div class="form-group mb-0 border custom-select">
                      <select
                        v-model="searchModel.specialists"
                        class="form-control border-0"
                      >
                        <option :value="null">{{ $t("search") }}</option>
                        <option
                          :value="item.id"
                          :key="i"
                          v-for="(item, i) in specialists"
                        >
                          {{ item.lang }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="search-input search-calendar-line">
                    <div class="mb-0 border">
                      <input
                        class="form-control"
                        type="date"
                        v-model="searchModel.date"
                        :min="currentDate"
                      />
                    </div>
                  </div>
                  <div class="form-search-btn">
                    <b-button class="btn" @click="handleSearch">
                      {{ $t("search") }}
                    </b-button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="banner-img aos" data-aos="fade-up">
              <img
                style="opacity: 0"
                src="@/assets/img/banner-img.png"
                class="img-fluid"
                alt="patient-image"
              />
              <div class="banner-img1" style="opacity: 0">
                <img
                  src="@/assets/img/banner/banner-img3.svg"
                  class="img-fluid"
                  alt="checkup-image"
                />
              </div>
              <!-- <div class="banner-img2">
              <img
                src="@/assets/img/banner/banner-img2.svg"
                class="img-fluid"
                alt="doctor-slide"
              />
            </div>
            <div class="banner-img3">
              <img
                src="@/assets/img/banner/banner-img3.svg"
                class="img-fluid"
                alt="doctors-list"
              />
            </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="specialities-section-one d-none">
      <div class="container">
        <div class="service-sec-one">
          <div
            class="row row-cols-7 row-cols-xxl-7 row-cols-xl-4 row-cols-lg-4 rows-cols-md-6 justify-content-center d-none"
          >
            <div class="col-12 d-flex col-xxl col-lg-3 col-sm-6">
              <a href="javascript:void(0);" class="serv-wrap blue-bg flex-fill">
                <span>
                  <img
                    src="@/assets/img/icons/service-01.svg"
                    alt="heart-image"
                  />
                </span>
                <h4>Book Appointment</h4>
              </a>
            </div>
            <div class="col-12 d-flex col-xxl col-lg-3 col-sm-6">
              <a
                href="javascript:void(0);"
                class="serv-wrap green-bg flex-fill"
              >
                <span>
                  <img
                    src="@/assets/img/icons/service-02.svg"
                    alt="heart-image"
                  />
                </span>
                <h4>Lab Testing Services</h4>
              </a>
            </div>
            <div class="col-12 d-flex col-xxl col-lg-3 col-sm-6">
              <a href="javascript:void(0);" class="serv-wrap info-bg flex-fill">
                <span>
                  <img
                    src="@/assets/img/icons/service-03.svg"
                    alt="heart-image"
                  />
                </span>
                <h4>Medicines & Supplies</h4>
              </a>
            </div>
            <div class="col-12 d-flex col-xxl col-lg-3 col-sm-6">
              <a href="javascript:void(0);" class="serv-wrap red-bg flex-fill">
                <span>
                  <img
                    src="@/assets/img/icons/service-04.svg"
                    alt="heart-image"
                  />
                </span>
                <h4>Hospitals / Clinics</h4>
              </a>
            </div>
            <div class="col-12 d-flex col-xxl col-lg-3 col-sm-6">
              <a
                href="javascript:void(0);"
                class="serv-wrap success-bg flex-fill"
              >
                <span>
                  <img
                    src="@/assets/img/icons/service-05.svg"
                    alt="heart-image"
                  />
                </span>
                <h4>Health Care Services</h4>
              </a>
            </div>
            <div class="col-12 d-flex col-xxl col-lg-3 col-sm-6">
              <a href="javascript:void(0);" class="serv-wrap pink-bg flex-fill">
                <span>
                  <img
                    src="@/assets/img/icons/service-06.svg"
                    alt="heart-image"
                  />
                </span>
                <h4>Talk to Doctor’s</h4>
              </a>
            </div>
            <div class="col-12 d-flex col-xxl col-lg-3 col-sm-6">
              <a
                href="javascript:void(0);"
                class="serv-wrap danger-bg flex-fill"
              >
                <span>
                  <img
                    src="@/assets/img/icons/service-07.svg"
                    alt="heart-image"
                  />
                </span>
                <h4>Home Care Services</h4>
              </a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 aos" data-aos="fade-up">
            <div class="section-header-one section-header-slider">
              <h2 class="section-title">Specialities</h2>
            </div>
          </div>
          <div class="col-md-6 aos" data-aos="fade-up">
            <div class="owl-nav slide-nav-1 text-end nav-control"></div>
          </div>
        </div>
        <div
          class="owl-carousel specialities-slider-one owl-theme aos"
          data-aos="fade-up"
        >
          <Carousel
            :wrap-around="true"
            :settings="settings"
            :breakpoints="breakpoints"
          >
            <Slide v-for="item in IndexBanner" :key="item.id">
              <div class="item slide-content-container">
                <div class="specialities-item">
                  <div class="specialities-img">
                    <span
                      ><img
                        :src="
                          require(`@/assets/img/specialities/${item.Image}`)
                        "
                        alt="specialities"
                    /></span>
                  </div>
                  <p>{{ item.Title }}</p>
                </div>
              </div>
            </Slide>
            <template #addons>
              <Navigation />
            </template>
          </Carousel>
        </div>
        <div class="specialities-btn aos" data-aos="fade-up">
          <router-link to="/search" class="btn">
            See All Specialities
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import IndexBanner from "@/assets/json/indexbanner.json";
import "vue3-carousel/dist/carousel.css";
import { getSpecialists } from "@/api/lookups";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      searchModel: {
        date: "",
        specialists: null,
        name: "",
      },
      specialists: [],
      IndexBanner: IndexBanner,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 6,
          snapAlign: "start",
        },
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
    this.getspecialists();
    this.searchModel.date = this.currentDate;
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  methods: {
    async getspecialists() {
      let res = await getSpecialists();
      this.specialists = res.data;
    },
    handleSearch() {
      this.$router.push({
        path: "/search",
        query: {
          name: this.searchModel.name,
          specialists: this.searchModel.specialists,
          date: this.searchModel.date,
          page: 1,
        },
      });
    },
  },
  computed: {
    ...mapGetters(["isLoggedIn", "userData", "currentDate"]),
    dynamicBackground() {
      return `url(./banner-sec-bg-13.jpg)`;
    },
  },
};
</script>
<style scoped>
/* .banner-section {
  background-image: none !important;
} */
.banner-section::after {
  background-image: linear-gradient(45deg, #048aa7, #46a8be) !important;
}
</style>
