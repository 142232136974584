<template>
  <div class="">
    <form class="row gy-3">
      <!-- first_name -->
      <div class="col-lg-6">
        <div class="form-focus h-auto">
          <input
            type="text"
            placeholder=" "
            class="form-control floating"
            v-model="doctor.first_name"
          />
          <label class="focus-label">{{ $t("register.first_name") }}</label>

          <div class="py-2" v-if="v$.first_name.$error">
            <small
              v-if="v$.first_name.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
          </div>
        </div>
      </div>

      <!-- last_name -->
      <div class="col-lg-6">
        <div class="form-focus h-auto">
          <input
            type="text"
            class="form-control floating"
            placeholder=" "
            v-model="doctor.last_name"
          />
          <label class="focus-label">{{ $t("register.last_name") }}</label>

          <div class="py-2" v-if="v$.last_name.$error">
            <small
              v-if="v$.last_name.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
          </div>
        </div>
      </div>
      <!-- name_ar -->
      <div class="col-lg-6">
        <div class="form-focus h-auto">
          <input
            type="text"
            placeholder=" "
            class="form-control floating"
            v-model="doctor.name_ar"
          />
          <label class="focus-label">{{ $t("name_ar") }}</label>

          <div class="py-2" v-if="v$.name_ar.$error">
            <small
              v-if="v$.name_ar.isValidArabicLetter.$invalid"
              class="text-warning d-block"
            >
              {{ $t("isValidArabicLetter") }}
            </small>
          </div>
        </div>
      </div>
      <!-- job_title_ar -->
      <div class="col-lg-6">
        <div class="form-focus h-auto">
          <input
            type="text"
            placeholder=" "
            class="form-control floating"
            v-model="doctor.job_title_ar"
          />
          <label class="focus-label">{{ $t("job_title_ar") }}</label>

          <div class="py-2" v-if="v$.job_title_ar.$error">
            <small
              v-if="v$.job_title_ar.isValidArabicLetter.$invalid"
              class="text-warning d-block"
            >
              {{ $t("isValidArabicLetter") }}
            </small>
          </div>
        </div>
      </div>
      <!-- job_title_en -->
      <div class="col-lg-6">
        <div class="form-focus h-auto">
          <input
            type="text"
            placeholder=" "
            class="form-control floating"
            v-model="doctor.job_title_en"
          />
          <label class="focus-label">{{ $t("job_title_en") }}</label>

          <div class="py-2" v-if="v$.job_title_en.$error">
            <small
              v-if="v$.job_title_en.isValidEnglishLetter.$invalid"
              class="text-warning d-block"
            >
              {{ $t("isValidEnglishLetter") }}
            </small>
          </div>
        </div>
      </div>
      <!-- job_title_fr -->
      <div class="col-lg-6">
        <div class="form-focus h-auto">
          <input
            type="text"
            placeholder=" "
            class="form-control floating"
            v-model="doctor.job_title_fr"
          />
          <label class="focus-label">{{ $t("job_title_fr") }}</label>

          <div class="py-2" v-if="v$.job_title_fr.$error">
            <small
              v-if="v$.job_title_fr.isValidFrenchLetter.$invalid"
              class="text-warning d-block"
            >
              {{ $t("isValidFrenchLetter") }}
            </small>
          </div>
        </div>
      </div>
      <!-- bio_ar -->
      <div class="col-lg-6">
        <div class="form-focus h-auto">
          <textarea
            style="min-height: 150px"
            class="form-control floating"
            v-model="doctor.bio_ar"
          />
          <label class="focus-label">{{ $t("bio_ar") }}</label>

          <div class="py-2" v-if="v$.bio_ar.$error">
            <small
              v-if="v$.bio_ar.isValidArabicLetter.$invalid"
              class="text-warning d-block"
            >
              {{ $t("isValidArabicLetter") }}
            </small>
          </div>
        </div>
      </div>
      <!-- bio_en -->
      <div class="col-lg-6">
        <div class="form-focus h-auto">
          <textarea
            style="min-height: 150px"
            class="form-control floating"
            v-model="doctor.bio_en"
          />
          <label class="focus-label">{{ $t("bio_en") }}</label>

          <div class="py-2" v-if="v$.bio_en.$error">
            <small
              v-if="v$.bio_en.isValidEnglishLetter.$invalid"
              class="text-warning d-block"
            >
              {{ $t("isValidEnglishLetter") }}
            </small>
          </div>
        </div>
      </div>
      <!-- bio_fr -->
      <div class="col-lg-6">
        <div class="form-focus h-auto">
          <textarea
            style="min-height: 150px"
            class="form-control floating"
            v-model="doctor.bio_fr"
          />
          <label class="focus-label">{{ $t("bio_fr") }}</label>

          <div class="py-2" v-if="v$.bio_fr.$error">
            <small
              v-if="v$.bio_fr.isValidFrenchLetter.$invalid"
              class="text-warning d-block"
            >
              {{ $t("isValidFrenchLetter") }}
            </small>
          </div>
        </div>
      </div>
      <!-- email -->
      <div class="col-lg-6">
        <div class="form-focus h-auto">
          <input
            type="email"
            class="form-control floating"
            placeholder=" "
            v-model="doctor.email"
          />
          <label class="focus-label">{{ $t("register.email") }}</label>

          <div class="py-2" v-if="v$.email.$error">
            <small
              v-if="v$.email.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
            <small
              v-else-if="v$.email.email.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.email") }}
            </small>
          </div>
        </div>
      </div>

      <!-- mobile -->
      <div class="col-lg-6">
        <div class="form-focus h-auto">
          <vue-tel-input
            @validate="checkphone"
            class="form-control floating"
            mode="international"
          ></vue-tel-input>

          <!-- <label class="focus-label">{{ $t("register.mobile") }}</label> -->

          <div class="py-2" v-if="v$.mobile.$error">
            <small
              v-if="v$.mobile.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="form-wrap mb-0">
          <label class="col-form-label">{{ $t("upload.photo") }}</label>
          <div class="upload-file">
            <input
              type="file"
              @change="doctor.photo = $event.target.files[0]"
            />
            <p>
              {{ doctor.photo ? doctor.photo.name : $t("upload.instructions") }}
            </p>
          </div>
        </div>

        <div class="py-2" v-if="v$.photo.$error">
          <small v-if="v$.photo.required.$invalid" class="text-warning d-block">
            {{ $t("validation.requiredField") }}
          </small>
        </div>
      </div>

      <!-- specialist_id -->
      <div class="col-lg-6">
        <div class="form-wrap">
          <label class="col-form-label">{{ $t("register.specialist") }}</label>
          <vue-multiselect
            v-model="doctor.specialist_id"
            track-by="id"
            label="lang"
            :options="specialists"
            :placeholder="$t('select')"
          ></vue-multiselect>

          <div class="py-2" v-if="v$.specialist_id.$error">
            <small
              v-if="v$.specialist_id.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
          </div>
        </div>
      </div>

      <!-- nationality_id -->
      <div class="col-lg-6">
        <div class="form-wrap">
          <label class="col-form-label">{{ $t("register.nationality") }}</label>
          <vue-multiselect
            v-model="doctor.nationality_id"
            track-by="id"
            label="lang"
            :options="countries"
            :placeholder="$t('select')"
          ></vue-multiselect>

          <div class="py-2" v-if="v$.nationality_id.$error">
            <small
              v-if="v$.nationality_id.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
          </div>
        </div>
      </div>

      <!-- country_id -->
      <div class="col-lg-6">
        <div class="form-wrap">
          <label class="col-form-label">{{ $t("register.country") }}</label>
          <vue-multiselect
            v-model="doctor.country_id"
            track-by="id"
            label="lang"
            :options="countries"
            :placeholder="$t('select')"
          ></vue-multiselect>

          <div class="py-2" v-if="v$.country_id.$error">
            <small
              v-if="v$.country_id.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
          </div>
        </div>
      </div>

      <!-- gender -->
      <div class="col-lg-6">
        <div class="form-wrap">
          <label class="col-form-label">{{ $t("register.gender") }}</label>
          <select v-model="doctor.gender" class="form-control">
            <option :value="null">{{ $t("select") }}</option>
            <option value="male">{{ $t("male") }}</option>
            <option value="female">{{ $t("female") }}</option>
          </select>
          <div class="py-2" v-if="v$.gender.$error">
            <small
              v-if="v$.gender.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
          </div>
        </div>
      </div>

      <!-- languages -->
      <div class="col-lg-12">
        <div class="form-wrap">
          <label class="col-form-label">{{ $t("register.languages") }}</label>
          <vue-multiselect
            v-model="doctor.languages"
            :multiple="true"
            :options="$i18n.availableLocales"
            :placeholder="$t('select')"
          ></vue-multiselect>

          <div class="py-2" v-if="v$.languages.$error">
            <small
              v-if="v$.languages.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
          </div>
        </div>
      </div>

      <!-- birthdate -->
      <div class="col-md-6">
        <div class="form-wrap mb-0">
          <label class="col-form-label"> {{ $t("register.birthdate") }} </label>
          <div class="form-icon">
            <datepicker
              v-model="doctor.birthdate"
              class="datetimepicker form-control"
              :upper-limit="get18yodate()"
              :startingViewDate="get18yodate()"
              :input-format="dateFormat"
              :placeholder="$t('select')"
            />
            <span class="icon"
              ><i class="fa-regular fa-calendar-days"></i
            ></span>
          </div>

          <div class="py-2" v-if="v$.birthdate.$error">
            <small
              v-if="v$.birthdate.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
          </div>
        </div>
      </div>

      <!-- national_id_number -->
      <div class="col-lg-6 d-flex align-items-end">
        <div class="form-focus h-auto w-100">
          <input
            type="text"
            class="form-control floating"
            placeholder=" "
            v-model="doctor.national_id_number"
          />
          <label class="focus-label">{{
            $t("register.national_id_number")
          }}</label>

          <div class="py-2" v-if="v$.national_id_number.$error">
            <small
              v-if="v$.national_id_number.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
          </div>
        </div>
      </div>

      <!-- licence -->
      <div class="col-lg-6 d-flex align-items-end">
        <div class="form-focus h-auto w-100">
          <label class="col-form-label"
            >{{ $t("licence") }} <span class="text-danger">*</span></label
          >
          <input
            type="text"
            class="form-control floating"
            placeholder=" "
            v-model="doctor.licence"
          />
          <div class="py-2" v-if="v$.licence.$error">
            <small
              v-if="v$.licence.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
          </div>
        </div>
      </div>

      <!-- licence_date -->
      <div class="col-md-6">
        <div class="form-wrap mb-0">
          <label class="col-form-label"
            >{{ $t("licence_date") }} <span class="text-danger">*</span></label
          >
          <div class="form-icon">
            <datepicker
              v-model="doctor.licence_date"
              class="datetimepicker form-control"
              :upperLimit="getTomorrowDate()"
              :editable="true"
              :clearable="false"
              :input-format="dateFormat"
              :placeholder="$t('select')"
            />
            <span class="icon"
              ><i class="fa-regular fa-calendar-days"></i
            ></span>
          </div>

          <div class="py-2" v-if="v$.licence_date.$error">
            <small
              v-if="v$.licence_date.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="form-wrap mb-0">
          <label class="col-form-label">{{ $t("medical_licence") }}</label>
          <div class="upload-file">
            <input
              type="file"
              @change="doctor.medical_licence = $event.target.files[0]"
            />
            <p>
              {{
                doctor.medical_licence
                  ? doctor.medical_licence.name
                  : $t("upload.instructions")
              }}
            </p>
          </div>

          <div class="py-2" v-if="v$.medical_licence.$error">
            <small
              v-if="v$.medical_licence.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
          </div>
        </div>
      </div>
      <div class="">
        <p class="">
          <small>
            {{ $t("fillAllInput") }}
          </small>
          <b-spinner small type="grow" class="mx-2" v-if="pdfLoad"></b-spinner>
          <br />
          <a href="#" class="text-primary" @click="getContract">
            {{ $t("agreeTermsConditions") }}
          </a>
        </p>
        <router-link class="forgot-link" to="/login">
          {{ $t("register.alreadyHaveAccount") }}
        </router-link>
      </div>

      <b-button
        :disabled="disabledBtn"
        class="btn btn-primary btn-lg login-btn"
        @click="submitForm"
      >
        <span v-if="!loading">{{ $t("buttons.sign_up") }}</span>
        <b-spinner v-else></b-spinner>
      </b-button>
    </form>
    <a-modal
      v-model:open="open"
      :title="$t('notice')"
      centered
      width="80%"
      :footer="null"
      @cancel="open = false"
    >
      <div class="mb-4">
        <iframe
          :src="pdfUrl"
          width="100%"
          height="500px"
          style="border: none"
        ></iframe>
      </div>
      <div class="d-flex">
        <b-button class="btn btn-danger btn-lg login-btn" @click="open = false">
          <span>{{ $t("reject") }}</span>
        </b-button>
        <b-button
          class="btn btn-primary mx-2 btn-lg login-btn"
          @click="(open = false), (disabledBtn = false)"
        >
          <span>{{ $t("accept") }}</span>
        </b-button>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { getCountries, getSpecialists } from "@/api/lookups";
import { doctorApplication, doctorPreviewContract } from "@/api/doctor";
import { objectToFormData } from "@/utils/helpers";
import useVuelidate from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import { onMounted, computed, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { isValidArabicContent, isValidArabicLetter } from "@/utils/arabic";
import {
  isValidEnglishContent,
  isValidEnglishLetter,
  isValidFrenchLetter,
} from "@/utils/english";

const router = useRouter();
const dateFormat = "dd-MM-yyyy";

const countries = ref([]);
const specialists = ref([]);
const pdfUrl = ref("");
const pdfLoad = ref(false);
const open = ref(false);
const disabledBtn = ref(true);

const genders = computed(() => [
  { id: "male", text: "Male" },
  { id: "female", text: "female" },
]);

const get18yodate = () => {
  const today = new Date();
  const minDate = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );

  return minDate;
};

const getTomorrowDate = () => {
  const today = new Date();
  const minDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  return minDate;
};

onMounted(async () => {
  const countriesList = await getCountries();
  const specialistsList = await getSpecialists();

  countries.value = countriesList.data;
  specialists.value = specialistsList.data;
});

const doctor = reactive({
  first_name: "",
  last_name: "",
  name_ar: "",
  job_title_ar: "",
  job_title_en: "",
  job_title_fr: "",
  bio_ar: "",
  bio_en: "",
  bio_fr: "",
  email: "",
  mobile: null,
  photo: null,
  specialist_id: null,
  nationality_id: null,
  country_id: null,
  gender: null,
  languages: [],
  birthdate: null,
  medical_licence: null,
  national_id_number: "",
  licence: "",
  licence_date: null,
});

const rules = {
  first_name: { required },
  last_name: { required },
  name_ar: { isValidArabicLetter },
  job_title_ar: { isValidArabicLetter },
  job_title_en: { isValidEnglishLetter },
  job_title_fr: { isValidFrenchLetter },
  bio_ar: { isValidArabicLetter },
  bio_en: { isValidEnglishLetter },
  bio_fr: { isValidFrenchLetter },
  email: { required, email },
  mobile: { required },
  photo: { required },
  specialist_id: { required },
  nationality_id: { required },
  country_id: { required },
  gender: { required },
  languages: { required },
  birthdate: { required },
  medical_licence: { required },
  national_id_number: { required },
  licence: { required },
  licence_date: { required },
};
const loading = ref(false);
const v$ = useVuelidate(rules, doctor);

const getContract = async () => {
  await v$.value.$validate();
  if (v$.value.$invalid) {
    return;
  }
  pdfLoad.value = true;
  const payload = objectToFormData({
    ...doctor,
    specialist_id: doctor.specialist_id.id,
    nationality_id: doctor.nationality_id.id,
    country_id: doctor.country_id.id,
    gender: doctor.gender.id,
    birthdate: doctor.birthdate.toLocaleDateString("es-CL"),
    licence_date: doctor.licence_date.toLocaleDateString("es-CL"),
  });

  try {
    let res = await doctorPreviewContract(payload);
    if (res) {
      pdfUrl.value = res.data.url;
      open.value = true;
    }
  } catch (error) {
    console.log(error);
  } finally {
    pdfLoad.value = false;
  }
};

const submitForm = async () => {
  await v$.value.$validate();
  if (v$.value.$invalid) {
    return;
  }
  loading.value = true;

  const fd = objectToFormData({
    ...doctor,
    specialist_id: doctor.specialist_id.id,
    nationality_id: doctor.nationality_id.id,
    country_id: doctor.country_id.id,
    gender: doctor.gender.id,
    birthdate: doctor.birthdate.toLocaleDateString("es-CL"),
    licence_date: doctor.licence_date.toLocaleDateString("es-CL"),
  });

  try {
    await doctorApplication(fd);
    router.push("/login");
    reset();
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
};

const checkphone = (e) => {
  if (e.valid) {
    doctor.mobile = e.number;
  }
};
const reset = () => {
  doctor.value = {
    first_name: "",
    name_ar: "",
    job_title_ar: "",
    job_title_en: "",
    job_title_fr: "",
    bio_ar: "",
    bio_en: "",
    bio_fr: "",
    last_name: "",
    email: "",
    mobile: null,
    photo: null,
    specialist_id: null,
    nationality_id: null,
    country_id: null,
    gender: null,
    languages: [],
    birthdate: null,
    medical_licence: null,
    national_id_number: "",
    licence: "",
    licence_date: null,
  };
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
