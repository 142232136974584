<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb1
      :title="$t('serviceGuide')"
      :text="$t('home')"
      :text1="$t('serviceGuide')"
    />
    <div class="">
      <div class="d-flex justify-content">
        <iframe
          src="https://midocean-generic.s3.amazonaws.com/howtouseclinic.mp4?autoplay=1&loop=1"
          style="flex-grow: 1; height: 100vh"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <footerindex></footerindex>
  </div>
</template>

<script>
export default {};
</script>
