<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb1
      :title="this.$t('appointments')"
      :text="this.$t('home')"
      :text1="this.$t('appointments')"
    />

    <!-- Page Content -->
    <div class="content">
      <div class="container">
        <div class="row">
          <doctorsidebar></doctorsidebar>

          <div class="col-md-7 col-lg-8 col-xl-9">
            <div class="dashboard-header">
              <h3>{{ $t("appointments") }}</h3>
            </div>
            <div class="appointment-tab-head">
              <form action="" class="flex-grow-1">
                <div class="row">
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <select class="form-control" v-model="searchModel.status">
                      <option :value="null" selected>
                        {{ $t("all_status") }}
                      </option>
                      <option
                        v-for="(item, i) in statusList"
                        :key="i"
                        :value="item"
                      >
                        {{ item }}
                      </option>
                    </select>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <input
                      class="form-control"
                      type="date"
                      v-model="searchModel.date_from"
                    />
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <input
                      class="form-control"
                      type="date"
                      v-model="searchModel.date_to"
                    />
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <button
                      style="width: 140px; padding: 10px"
                      class="btn btn-primary"
                      :disabled="loading"
                      @click="(searchModel.page = 1), getDataList()"
                    >
                      {{ $t("search") }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="d-flex justify-content-center py-5" v-if="loading">
              <div
                class="spinner-border"
                style="width: 3rem; height: 3rem"
                role="status"
              ></div>
            </div>
            <div class="py-4" v-else-if="!loading && searchResult.length">
              <div class="" v-for="(item, i) in searchResult" :key="i">
                <div class="appointment-wrap">
                  <ul>
                    <li>
                      <div class="patinet-information">
                        <div>
                          <img
                            v-if="item?.patient?.photo"
                            :src="item.patient.photo"
                            alt="User Image"
                          />
                          <img
                            v-else
                            src="@/assets/img/patient-placeholder.png"
                            alt=""
                          />
                        </div>
                        <div class="patient-info">
                          <h6>
                            <div>
                              {{ item?.patient_name.slice(0, 12) }}
                              {{ item?.patient_name?.length >= 12 ? ".." : "" }}
                            </div>
                            <span
                              class="badge ms-2"
                              :class="
                                item?.status == 'cancelled'
                                  ? 'bg-danger'
                                  : 'bg-info'
                              "
                            >
                              {{ item?.status }}
                            </span>
                          </h6>
                        </div>
                      </div>
                    </li>
                    <li class="appointment-info">
                      <p>
                        <i class="fa-solid fa-clock"></i>
                        {{ item.time_from }} - {{ item.time_to }}
                      </p>
                      <ul class="d-flex apponitment-types">
                        <li>{{ item.day }}</li>
                        <li>{{ item.date }}</li>
                      </ul>
                    </li>
                    <li v-if="item.status != 'cancelled'">
                      <a
                        class="text-white bg-danger btn"
                        :class="item.status == 'cancelled' ? '' : ''"
                        @click="startCnacelAppointment(item)"
                        href="#account_details"
                        data-bs-toggle="modal"
                        >{{ $t("cancel") }}
                      </a>
                      <a
                        v-if="showMeetingLink(item)"
                        class="btn bg-info ms-2"
                        target="_blank"
                        :href="item.meeting_link"
                      >
                        <img
                          style="width: 23px"
                          src="@/assets/img/icons/customer-service-1.svg"
                          alt=""
                        />
                      </a>
                    </li>
                    <li class="appointment-start">
                      <router-link
                        :to="`/doctor-appointment-details?app=${item.id}`"
                        class="start-link"
                        >{{ $t("details") }}</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <upcoming></upcoming>
              <pagination
                :pagination="pagination"
                @changePage="gotoPage"
              ></pagination>
            </div>
            <div class="d-flex justify-content-center py-5" v-else>
              {{ $t("noSearchResult") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <footerindex></footerindex>
    <Teleport to="body">
      <div
        class="modal fade custom-modal custom-modal-two modal-md"
        id="account_details"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <button
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref="modalClose"
              class="d-none"
            >
              <span><i class="fa-solid fa-x"></i></span>
            </button>
            <div class="modal-body py-5">
              <h2 class="text-center mb-5">
                {{ $t("cancelAppointmentsConfirmation") }}
              </h2>
              <div class="d-flex justify-content-center">
                <button
                  class="btn btn-danger py-2 px-5 mx-2"
                  @click="cnacelAppointment"
                >
                  {{ $t("yes") }}
                </button>
                <button
                  class="btn btn-success py-2 px-5 mx-2"
                  href="#account_details"
                  data-bs-toggle="modal"
                >
                  {{ $t("no") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script>
import { doctorAppointments, cancelDoctorAppointments } from "@/api/doctor";
import pagination from "@/components/frontend/pagination/index.vue";

export default {
  data() {
    return {
      title: "Appointments",
      text: "Home",
      text1: "Appointments",
      loading: false,
      statusList: ["request", "accepted", "done", "cancelled"],
      pagination: {
        pageNumber: 1,
        totalCount: 0,
        totalPages: 0,
        per_page: 0,
      },
      searchResult: [],
      searchModel: {
        status: null,
        date_from: "",
        date_to: "",
        page: 1,
      },
      currentItem: {},
    };
  },
  components: {
    pagination,
  },
  created() {
    this.loadSearchModelFromQuery();
    this.getDataList();
  },
  methods: {
    showMeetingLink(item) {
      const currentDateTime = new Date();
      const meetingDateTime = new Date(`${item.date} ${item.time_from}`);

      meetingDateTime.setMinutes(meetingDateTime.getMinutes() - 15);

      return item.meeting_link && currentDateTime < meetingDateTime;
    },
    loadSearchModelFromQuery() {
      const query = this.$route.query;
      if (query) {
        this.searchModel.date_from = query.date_from || "";
        this.searchModel.date_to = query.date_to || "";
        this.searchModel.page = query.page || 1;
        this.searchModel.status = query.status || "";
      }
    },
    async getDataList() {
      this.loading = true;

      try {
        const response = await doctorAppointments(this.searchModel);

        if (response) {
          this.searchResult = response.data.data;
          this.pagination.totalPages = response.data.meta.last_page;
          this.pagination.totalCount = response.data.meta.total;
          this.pagination.pageNumber = response.data.meta.current_page;
          this.pagination.per_page = response.data.meta.per_page;
          this.updateQueryParams();
        }
      } catch (error) {
        console.error("Error fetching appointments list:", error);
      } finally {
        this.loading = false;
      }
    },
    updateQueryParams() {
      this.$router.push({
        query: {
          date_from: this.searchModel.date_from,
          date_to: this.searchModel.date_to,
          status: this.searchModel.status,
          page: this.searchModel.page,
        },
      });
    },
    gotoPage(page) {
      this.pagination.pageNumber = Number(page);
      this.$router.push({ query: { ...this.$route.query, page } });
      this.getDataList();
    },
    startCnacelAppointment(item) {
      this.currentItem = item;
    },
    async cnacelAppointment() {
      try {
        let res = await cancelDoctorAppointments(this.currentItem.id);
        if (res) {
          this.$refs.modalClose.click();
          this.gotoPage(this.pageNumber);
        }
      } catch (error) {
        console.error("Error fetching roles list:", error);
      }
    },
  },
};
</script>
