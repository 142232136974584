<template>
  <div class="">
    <div class="" v-if="searchResult.length">
      <div class="card" v-for="item in searchResult" :key="item.id">
        <div class="card-body">
          <div class="doctor-widget">
            <div class="doc-info-left">
              <div class="doctor-img border p-1 rounded">
                <a
                  href="#account_details"
                  data-bs-toggle="modal"
                  @click="handleDetails(item)"
                >
                  <img :src="item.photo" class="img-fluid" alt="User Image" />
                </a>
              </div>
              <div class="doc-info-cont">
                <h4 class="doc-name">
                  <a
                    href="#account_details"
                    data-bs-toggle="modal"
                    @click="handleDetails(item)"
                  >
                    {{ item.name }}
                  </a>
                </h4>
                <p class="doc-speciality" v-if="item.job_title">
                  {{ item.job_title }}
                </p>
                <h5 class="doc-department">
                  <!-- <img
                  :src="
                    require(`@/assets/img/specialities/${item.SpecialityImg}`)
                  "
                  class="img-fluid"
                  alt="Speciality"
                /> -->
                </h5>
                <div class="clinic-details">
                  <p class="doc-location">
                    <i class="fas fa-map-marker-alt"></i> {{ item.country }}
                  </p>
                </div>
              </div>
            </div>
            <div class="doc-info-right">
              <!-- <div class="clini-infos">
                <ul>
                  <li><i class="fa fa-mobile"></i> {{ item.mobile }}</li>
                  <li><i class="far fa-comment"></i> {{ item.Feedback }}</li>
                  <li>
                    <i class="fas fa-map-marker-alt"></i> {{ item.country }}
                  </li>
                </ul>
              </div> -->
              <div class="clinic-booking">
                <router-link
                  class="view-pro-btn"
                  :to="`/request-appointment?dr=${item.id}&dt=${$route.query.date}`"
                >
                  {{ $t("requestAppointment") }}
                </router-link>
              </div>
            </div>
          </div>
          <div class="d-flex py-2" v-if="availableTimes?.length">
            <div
              class="rounden p-2 btn-info me-2"
              v-for="time in item.availableTimes"
              :key="time.id"
            >
              {{ time.time_from }} : {{ time.time_to }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="no-data" v-else>
      {{ $t("noSearchResult") }}
    </div>
    <Teleport to="body">
      <div
        class="modal fade custom-modal custom-modal-two modal-lg"
        id="account_details"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ $t("appointment_details") }}</h5>
              <button type="button" data-bs-dismiss="modal" aria-label="Close">
                <span><i class="fa-solid fa-x"></i></span>
              </button>
            </div>
            <div class="modal-body">
              <div>
                <div class="row">
                  <div class="col-md-6 h-100">
                    <h4 class="text-info">{{ $t("doctor_name") }}</h4>
                    <p>{{ currentDr.name }}</p>
                  </div>
                  <div class="col-md-6 h-100">
                    <h4 class="text-info">{{ $t("gender") }}</h4>
                    <p>{{ currentDr.gender }}</p>
                  </div>
                  <div class="col-md-6 h-100">
                    <h4 class="text-info">{{ $t("nationality") }}</h4>
                    <p>{{ currentDr.nationality }}</p>
                  </div>
                  <div class="col-md-6 h-100">
                    <h4 class="text-info">{{ $t("specialist") }}</h4>
                    <p>{{ currentDr.specialist }}</p>
                  </div>
                  <div class="col-md-6 h-100" v-if="currentDr.job_title">
                    <h4 class="text-info">{{ $t("job_title") }}</h4>
                    <p>{{ currentDr.job_title }}</p>
                  </div>
                  <div class="col-md-6 h-100" v-if="currentDr.job_title_fr">
                    <h4 class="text-info">{{ $t("job_title") }}</h4>
                    <p>{{ currentDr.job_title_fr }}</p>
                  </div>
                  <div class="col-12 h-100" v-if="currentDr.bio">
                    <h4 class="text-info">{{ $t("bio") }}</h4>
                    <p>{{ currentDr.bio }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script>
export default {
  props: ["searchResult"],
  data() {
    return {
      currentDr: {},
    };
  },
  methods: {
    handleDetails(item) {
      this.currentDr = item;
    },
  },
};
</script>
<style scoped>
.no-data {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
  padding: 20px 10px;
  text-align: center;
}
h4 {
  text-transform: capitalize;
}
</style>
