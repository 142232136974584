<template>
  <ul class="main-nav">
    <li class="has-submenu megamenu" :class="homeMenu ? 'active' : 'notactive'">
      <router-link to="/">{{ $t("home") }}</router-link>
    </li>
    <li
      class="has-submenu"
      v-if="isLoggedIn && userData.doctorOrPtient == 'isDoctor'"
      :class="doctorMenu ? 'active' : 'notactive'"
    >
      <a href="javascript:void(0);" @click="toggleVisibilitydoctors"
        >{{ $t("menu") }} <i class="fas fa-chevron-down"></i
      ></a>

      <ul
        class="submenu"
        :style="{ display: isVisibledoctor ? 'block' : 'none' }"
      >
        <li :class="currentPath == 'doctor-dashboard' ? 'active' : 'notactive'">
          <router-link to="/doctor-dashboard">{{
            $t("doctor_dashboard")
          }}</router-link>
        </li>
        <li :class="currentPath == 'appointments' ? 'active' : 'notactive'">
          <router-link to="/appointments">{{ $t("appointments") }}</router-link>
        </li>
        <li
          :class="currentPath == 'available-timings' ? 'active' : 'notactive'"
        >
          <router-link to="/available-timings">{{
            $t("available_timings")
          }}</router-link>
        </li>
        <li
          :class="
            currentPath == 'doctor-profile-settings' ? 'active' : 'notactive'
          "
        >
          <router-link to="/doctor-profile-settings">{{
            $t("profile_settings")
          }}</router-link>
        </li>
      </ul>
    </li>
    <li
      class="has-submenu"
      v-if="isLoggedIn && userData.doctorOrPtient == 'isPatient'"
      :class="patientsMenu ? 'active' : 'notactive'"
    >
      <a href="javascript:void(0);" @click="toggleVisibilitypatients"
        >{{ $t("menu") }} <i class="fas fa-chevron-down"></i
      ></a>
      <ul
        class="submenu"
        :style="{ display: isVisiblepatients ? 'block' : 'none' }"
      >
        <li :class="currentPath == 'search' ? 'active' : 'notactive'">
          <router-link :to="`/search?page=1&&date=${currentDate}`">{{
            $t("search_doctor")
          }}</router-link>
        </li>
        <li :class="currentPath == 'my-appointment' ? 'active' : 'notactive'">
          <router-link to="/my-appointment">{{
            $t("myAppointments")
          }}</router-link>
        </li>
        <li :class="currentPath == 'profile-settings' ? 'active' : 'notactive'">
          <router-link to="/profile-settings">{{
            $t("profile_settings")
          }}</router-link>
        </li>
        <li :class="currentPath == 'medical-details' ? 'active' : 'notactive'">
          <router-link to="/medical-details">{{
            $t("medical_details")
          }}</router-link>
        </li>
      </ul>
    </li>

    <li class="has-submenu" :class="pagesMenu ? 'active' : 'notactive'">
      <a href="javascript:void(0);" @click="toggleVisibilitypages"
        >{{ $t("pagesHeaderLink") }} <i class="fas fa-chevron-down"></i
      ></a>
      <ul
        class="submenu"
        :style="{ display: isVisiblepages ? 'block' : 'none' }"
      >
        <li :class="currentPath == 'about-us' ? 'active' : 'notactive'">
          <router-link to="about-us">{{ $t("about_us") }}</router-link>
        </li>
        <li :class="currentPath == 'contact-us' ? 'active' : 'notactive'">
          <router-link to="contact-us">{{ $t("contact_us") }}</router-link>
        </li>
        <li :class="currentPath == 'faq' ? 'active' : 'notactive'">
          <router-link to="/faq">{{ $t("faqs") }}</router-link>
        </li>
        <li
          class="has-submenu"
          :class="currentPath == 'log-list' ? 'active' : 'notactive'"
        >
          <a href="javascript:void(0);" @click="toggleVisibilityArticles">{{
            $t("articles")
          }}</a>
          <ul
            class="submenu inner-submenu"
            :style="{ display: isVisibleArticles ? 'block' : 'none' }"
          >
            <li
              :class="currentPath == 'blog-list' ? 'active' : 'notactive'"
              v-for="item in blogsCategoriesList"
              :key="item.id"
            >
              <router-link :to="`/blog-list?page=1&category_id=${item.id}`">
                {{ item.lang }}
              </router-link>
            </li>
          </ul>
        </li>
        <li
          :class="currentPath == 'administrative-team' ? 'active' : 'notactive'"
        >
          <router-link to="/administrative-team">{{
            $t("administrativeTeam")
          }}</router-link>
        </li> 
        <li :class="currentPath == 'doctors' ? 'active' : 'notactive'">
          <router-link to="/doctors">{{ $t("doctors") }}</router-link>
        </li>
        <li :class="currentPath == 'service-guide' ? 'active' : 'notactive'">
          <router-link to="/service-guide">{{
            $t("serviceGuide")
          }}</router-link>
        </li>
      </ul>
    </li>
    <li v-if="!isLoggedIn" class="login-link">
      <router-link to="/login"
        >{{ $t("login") }} / {{ $t("signup") }}</router-link
      >
    </li>
    <li v-if="!isLoggedIn" class="register-btn">
      <router-link to="/register" class="btn reg-btn"
        ><i class="feather-user"></i>{{ $t("signup") }}</router-link
      >
    </li>
    <li v-if="!isLoggedIn" class="register-btn">
      <router-link to="/login" class="btn btn-primary log-btn"
        ><i class="feather-lock"></i>{{ $t("login") }}</router-link
      >
    </li>
  </ul>
</template>
<script>
import { mapGetters } from "vuex";
import { blogsCategories } from "@/api/general";

export default {
  data() {
    return {
      isVisible: false,
      isVisiblehomes: false,
      isVisibledoctor: false,
      isVisiblepatients: false,
      isVisiblepatientsdoctor: false,
      isVisiblepatientssearch: false,
      isVisiblepatientsbooking: false,
      isVisiblepages: false,
      isVisibleArticles: false,
      isVisibleinvoice: false,
      isVisibleauthentication: false,
      isVisibleerror: false,
      currentDate: "",
      showModal: false,
      blogsCategoriesList: [],
    };
  },
  created() {
    this.currentDate = new Date().toISOString().split("T")[0];
    this.getBlogsCategories();
  },
  computed: {
    currentPath() {
      return this.$route.name;
    },
    homeMenu() {
      return this.$route.name == "index";
    },
    doctorMenu() {
      return (
        this.$route.name == "doctor-dashboard" ||
        this.$route.name == "appointments" ||
        this.$route.name == "available-timings" ||
        this.$route.name == "my-patients" ||
        this.$route.name == "patient-profile" ||
        this.$route.name == "edit-prescription" ||
        this.$route.name == "add-billing" ||
        this.$route.name == "doctor-profile-settings" ||
        this.$route.name == "reviews" ||
        this.$route.name == "add-prescription" ||
        this.$route.name == "edit-billing"
      );
    },
    patientsMenu() {
      return (
        this.$route.name == "map-list" ||
        this.$route.name == "search" ||
        this.$route.name == "doctor-profile" ||
        this.$route.name == "profile-settings" ||
        this.$route.name == "dependent"
      );
    },
    pagesMenu() {
      return (
        this.$route.name == "about-us" ||
        this.$route.name == "contact-us" ||
        this.$route.name == "blank-page" ||
        this.$route.name == "pricing" ||
        this.$route.name == "faq" ||
        this.$route.name == "maintenance" ||
        this.$route.name == "terms-condition" ||
        this.$route.name == "privacy-policy" ||
        this.$route.name == "components" ||
        this.$route.name == "invoice-view" ||
        this.$route.name == "login-email" ||
        this.$route.name == "login-phone" ||
        this.$route.name == "doctor-signup" ||
        this.$route.name == "patient-signup" ||
        this.$route.name == "blog-details" ||
        this.$route.name == "blog-list" ||
        this.$route.name == "service-guide" ||
        this.$route.name == "doctors" ||
        this.$route.name === "administrative-team"
      );
    },
    ...mapGetters(["currentLang", "isLoggedIn", "userData"]),
  },
  methods: {
    submitForm() {
      this.$router.push("/search");
    },
    async getBlogsCategories() {
      let res = await blogsCategories();
      this.blogsCategoriesList = res.data;
    },
    toggleElement() {
      this.isVisible = !this.isVisible;
    },
    toggleVisibilitydoctors() {
      this.isVisibledoctor = !this.isVisibledoctor;
    },
    toggleVisibilitypatients() {
      this.isVisiblepatients = !this.isVisiblepatients;
    },
    toggleVisibilitypatientsdoctors() {
      this.isVisiblepatientsdoctor = !this.isVisiblepatientsdoctor;
    },
    toggleVisibilitysearch() {
      this.isVisiblepatientssearch = !this.isVisiblepatientssearch;
    },
    toggleVisibilitybooking() {
      this.isVisiblepatientsbooking = !this.isVisiblepatientsbooking;
    },
    toggleVisibilitypages() {
      this.isVisiblepages = !this.isVisiblepages;
    },
    toggleVisibilityArticles() {
      this.isVisibleArticles = !this.isVisibleArticles;
    },
    toggleVisibilityinvoice() {
      this.isVisibleinvoice = !this.isVisibleinvoice;
    },
    toggleVisibilityauthentication() {
      this.isVisibleauthentication = !this.isVisibleauthentication;
    },
    toggleVisibilityerror() {
      this.isVisibleerror = !this.isVisibleerror;
    },
  },
};
</script>
